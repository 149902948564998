/* eslint-disable react/display-name */
import { Backdrop } from 'common/otto-ui';
import { useAuth } from 'common/roq-hooks/use-auth';
import { MainHeader } from 'components/main-header';
import { ROUTE_ENUM } from 'configuration/data/routers';
import React from 'react';

export const withAuthentication = function (Component: React.FunctionComponent): React.FC {
  return ({ ...props }) => {
    const { accessToken } = useAuth({ redirectTo: ROUTE_ENUM.LOGIN });
    if (!accessToken) {
      return (
        <>
          <MainHeader />
          <Backdrop fullScreen open={true} />
        </>
      );
    }
    return <Component {...props} />;
  };
};
