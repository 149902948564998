import { Badge, Button, ChevronDownIcon, ChevronUpIcon, LabelLarge, useTheme } from "@otto-finance/ui";
import { useMobile } from "common/roq-hooks/use-mobile";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

export type SubNavType = {
  label: string;
  url: string;
  isActive: boolean;
  isNew?: boolean;
  isBeta?: boolean;
};

export const SubNav = ({ pages }: { pages: SubNavType[]; }): JSX.Element => {
  const [css, theme] = useTheme();
  const isMobile = useMobile();
  return (
    <div className={css({ height: theme.sizing.scale1600 })}>
      <div className={css({
        display: 'grid',
        width: '100vw',
        height: theme.sizing.scale1600,
        position: 'fixed',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'hwb(0deg 100% 0% / 85%)',
        left: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyItems: 'center',
      })}>
        <div className={css({
          // maxWidth: `${theme.breakpoints.large}px`,
          width: '100%',
          display: 'flex',
          height: '100%',
          alignItems: 'stretch',
          [theme.mediaQuery.medium]: {
            paddingLeft: theme.sizing.scale800,
            paddingRight: theme.sizing.scale800,
          }
        })}>
          {isMobile ? <MobileSubNav pages={pages} /> : (
            pages.map((p, i) => (
              <Link key={`sub_p_${i}`} href={p.url} prefetch={false}>
                <div className={css({
                  ':first-child': {
                    paddingLeft: 0,
                  },
                  paddingLeft: theme.sizing.scale1000,
                  paddingRight: theme.sizing.scale1000,
                  fontWeight: theme.weights.medium,
                  fontSize: theme.typography.ParagraphSmall.fontSize,
                  color: p.isActive ? theme.colors.black : '#8F95A6',
                  lineHeight: theme.sizing.scale1600,
                  display: 'inline-block',
                  transition: 'color .3s',
                  ':hover': {
                    color: theme.colors.black
                  }
                })}>
                  <span>{p.label}</span>
                  {p.isNew ? (
                    <span className={css({
                      display: 'inline-block',
                      transform: 'translate3d(4px, -12px, 0)'
                    })}>
                      <Badge content="New" color="primary" />
                    </span>
                  ) : null}
                  {p.isBeta ? (
                    <span className={css({
                      display: 'inline-block',
                      transform: 'translate3d(4px, -12px, 0)'
                    })}>
                      <Badge content="Beta" overrides={{
                        Badge: {
                          style: () => ({
                            color: theme.colors.primary,
                            backgroundColor: theme.colors.blueScale200
                          })
                        }
                      }} />
                    </span>
                  ) : null}
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

const MobileSubNav = ({ pages }: { pages: SubNavType[]; }): JSX.Element => {
  const [css, theme] = useTheme();
  const router = useRouter();
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={css({
      display: 'grid',
      width: '100%',
      position: 'relative'
    })}>
      <div className={css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: theme.sizing.scale800,
        paddingLeft: theme.sizing.scale800
      })}>
        <div>
          <LabelLarge>{pages.find(f => f.isActive)?.label}</LabelLarge>
        </div>
        <Button onClick={() => setOpen(s => !s)} shape="circle" size="compact" kind="secondary">
          {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
      </div>
      {isOpen && (
        <div className={css({
          position: 'absolute',
          left: 0,
          width: '100%',
          top: '60px',
          backgroundColor: 'white',
        })}>
          <div className={css({
            display: 'grid',
          })}>
            {pages.map((p, i) => (
              <div
                key={`m_sub_p_${i}`}
                onClick={() => {
                  router.push(p.url);
                  setOpen(false);
                }}
                className={css({
                  paddingTop: theme.sizing.scale800,
                  paddingBottom: theme.sizing.scale800,
                  paddingRight: theme.sizing.scale800,
                  paddingLeft: theme.sizing.scale800,
                  color: '#8F95A6',
                  cursor: 'pointer',
                  ':hover': {
                    color: theme.colors.black
                  },
                  ':active': {
                    color: theme.colors.black,
                    backgroundColor: theme.colors.grayScale100
                  }
                })}>
                <LabelLarge color="inherit">{p.label}</LabelLarge>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
