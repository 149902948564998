import { Backdrop } from "common/otto-ui";
import { FailComponent } from "components/fail-component";
import { NoAccounts } from "components/no-accounts";
import { ROUTE_ENUM } from "configuration/data/routers";
import { useAppDispatch } from "configuration/redux/store";
import { WithRoleAuth } from "hocs";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { assetsSelector } from "slices/assets";
import { setPageName } from "slices/ui/ui.slice";

import { MainLayout } from "./main";
import { FeatureFlagEnum } from "configuration/data";
import { useFeature } from "views/login/hooks";

const pageChildren = [
  {
    label: 'Investment Portfolio',
    url: ROUTE_ENUM.WEALTH_INVESTMENTS,
    isActive: false,
    isNew: false,
    tier: null
  },
  {
    label: 'Investment Analysis',
    url: ROUTE_ENUM.WEALTH_INVESTMENT_ANALYSIS,
    isActive: false,
    isBeta: true,
    tier: FeatureFlagEnum.WEALTH_ANALYSIS
  },
  {
    label: 'Investment Ideas',
    url: ROUTE_ENUM.WEALTH_INVESTMENT_IDEAS,
    isActive: false,
    isNew: false,
    tier: FeatureFlagEnum.WEALTH_IDEAS
  },
  {
    label: 'Cashflow',
    url: ROUTE_ENUM.WEALTH_CASHFLOW,
    isActive: false,
    tier: null
  },
  {
    label: 'Assets',
    url: ROUTE_ENUM.WEALTH_ASSETS,
    isActive: false,
    tier: null
  },
  {
    label: 'Transactions',
    url: ROUTE_ENUM.TRANSACTIONS,
    isActive: false,
    isNew: true,
    tier: null
  }
];

export const WealthLayout: React.FC<{ title: string; fixedWidth?: boolean; pageName?: string; children?: ReactNode; }> = ({ title, fixedWidth, pageName, children }) => {
  const route = useRouter();
  const { loadingAccountsFinished, totalAssets } = useSelector(assetsSelector);
  const { hasAccess } = useFeature();
  const subPages = useMemo(() => pageChildren.filter(f => {
    if (f.tier) {
      return hasAccess(f.tier);
    }
    return true;
  }).map(p => ({ ...p, isActive: p.url === route?.pathname })), [route]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (pageName) {
      dispatch(setPageName(pageName));
    }
  }, []);
  return (
    <MainLayout title={title} pageTitle={title} subPages={subPages} fixedWidth={fixedWidth}>
      <WithRoleAuth permissionKey="wealth.read" failComponent={<FailComponent />}>
        {!loadingAccountsFinished ? (
          <Backdrop open={true} fullScreen />
        ) : (
          totalAssets === 0 ? (
            <NoAccounts
              subTitle='LIFE GOALS'
              title='Get full visibility of your cashflow.'
              image='/static/images/cashflow-preview.png'
              button={{ label: 'Add Assets', url: ROUTE_ENUM.WEALTH_ADD_ASSET }}
            />
          ) : (
            children
          )
        )}
      </WithRoleAuth>
    </MainLayout>
  );
};
