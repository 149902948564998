import { FailComponent } from 'components/fail-component';
import get from 'lodash/get';
import { ReactNode } from 'react';
import acls from 'shared/acls';
import { useLoginHook } from 'views/login/hooks';

interface WithRoleAuthProps {
  children?: ReactNode;
  permissionKey: string;
  failComponent?: ReactNode;
}

/**
 * Page wrapper to handle if the user has the correct roles and authentication
 * for the required page
 */
export const WithRoleAuth = ({ permissionKey, children, failComponent }: WithRoleAuthProps) => {
  const { userData: { type } } = useLoginHook();

  if (get(acls, permissionKey)?.includes(type)) {
    return children;
  }

  return failComponent || <FailComponent />;
};
