import clsx from 'clsx';
import { ChevronLeftIcon } from 'common/otto-ui/icons';
import { Logo } from 'components/logo';
import { MainHeader } from 'components/main-header';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef } from 'react';

export interface PatternInterface {
  children?: ReactNode;
  isBackShow?: boolean;
  hideSmallPattern?: boolean;
  hideSmallestPattern?: boolean;
  pageTitle: string;
  childMargin?: string;
  onBackClick?: () => void;
}

export const PatternLayout = ({
  children,
  isBackShow = true,
  onBackClick,
  pageTitle,
  childMargin,
}:PatternInterface) => {
  const router = useRouter();
  const smallPatternImg = useRef(null);
  const smallestPatternImg = useRef(null);

  const onBackClickHandler = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      router.back();
    }
  }

  const handleMobileKeyboardEvent = () => {
    if ('visualViewport' in window) {
      window.visualViewport.addEventListener('resize', function (event: any) {
        // NOSONAR
        if (smallestPatternImg.current && smallPatternImg.current) {
          if (event.target.height + 30 < document.scrollingElement.clientHeight) {
            smallestPatternImg.current?.classList?.add('hidden');
            smallPatternImg.current?.classList?.add('hidden');
          } else {
            smallestPatternImg.current?.classList?.remove('hidden');
            smallPatternImg.current?.classList?.remove('hidden');
          }
        }
      });
    }
  };

  useEffect(() => {
    handleMobileKeyboardEvent();
  }, []);

  const classes = {
    wrapper: clsx('w-screen h-screen overflow-y-auto dark:bg-background-dark-screen'),
    childWrapper: clsx(
      'flex flex-col items-center justify-center w-full pb-8',
      childMargin ?? 'lg:mt-20 sm:mt-6 md:mt-24',
    ),
    logoWrapper: clsx('w-full flex justify-center'),
  };

  return (
    <div className={classes.wrapper}>
      <MainHeader title={pageTitle} />
      <div className="flex w-2/5 sm:w-11/12 m-auto items-center pt-5">
        {isBackShow && (
          <div className="cursor-pointer" onClick={onBackClickHandler}>
            <ChevronLeftIcon />
          </div>
        )}
        <div className={classes.logoWrapper}>
        <Logo width={80} contrast />
        </div>
      </div>
      <div className={classes.childWrapper}>{children}</div>
    </div>
  );
};
