export { AuthLayout } from 'layouts/auth/auth.layout';
export { MainLayout } from 'layouts/main/main.layout';
export { PatternLayout } from 'layouts/pattern/pattern.layout';
export { NoAuthLayout } from './no-auth.layout';
export { AuthSplitLayout } from './auth/auth-split.layout';
export { WealthLayout } from './wealth.layout';
export { DemoLayout } from './demo.layout';

export type { AuthLayoutInterface } from 'layouts/auth/auth.layout';
export type { MainLayoutInterface } from 'layouts/main/main.layout';
