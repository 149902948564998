'use client';

import { themeUseStyletron } from '@otto-finance/ui';
import { MainHeader } from 'components/main-header';
import { SubNav, SubNavType } from 'components/sub-nav/sub-nav';
import { FeatureFlagEnum } from 'configuration/data';
import { ROUTE_ENUM } from 'configuration/data/routers';
import { useAppDispatch } from 'configuration/redux/store';
import { ColorTheme } from 'configuration/ui';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { routeTiers } from 'shared/data-info';
import { setPageName } from 'slices/ui/ui.slice';
import { __DEV__ } from 'utils';
import { useFeature, useLoginHook } from 'views/login/hooks';

export interface MainLayoutInterface {
  title?: string;
  pageTitle?: string;
  titleExtra?: ReactNode;
  pageColor?: string;
  className?: string;
  subPages?: SubNavType[];
  fixedWidth?: boolean;
  pageName?: string;
  children?: ReactNode;
}

export const MainLayout: React.FC<MainLayoutInterface> =
  ({
    title,
    pageTitle,
    subPages,
    className,
    pageColor = ColorTheme.mainLayoutColor,
    fixedWidth = true,
    children,
    pageName,
  }) => {
    const { isCustomer } = useLoginHook();
    const dispatch = useAppDispatch();
    const router = useRouter();
    const { hasAccess } = useFeature();
    const [css, theme] = themeUseStyletron();
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
      /* On loading the page we must check if the user tier allows them access to this
       * page - if not, we render a 404 page.
       */
      let isMounting = false;
      if (isCustomer && router.route !== ROUTE_ENUM.NOT_FOUND) {
        if (routeTiers.find(f => f.href === router.route && !hasAccess(f.tier as FeatureFlagEnum))) {
          router.replace(ROUTE_ENUM.NOT_FOUND);
        } else {
          isMounting = false;
        }
      } else {
        isMounting = false;
      }
      if (!isMounting) {
        dispatch(setPageName(pageName));
        setMounted(true);
      }
    }, []);
    return (
      <>
        <MainHeader title={title || pageTitle} />
        {/* TODO: Uncomment when Stuart free trial expires  */}
        {/* <WelcomePaymentModal isOpen={isCompanyDirect} onClose={null} /> */}
        <div className={css({
          display: 'grid',
          justifyItems: 'center',
          backgroundColor: pageColor,// TODO: get color from theme theme.colors.mono200,
          // gridRowGap: theme.sizing.scale800,
          minHeight: 'calc(100vh - 70px)',
          gridTemplateRows: 'max-content 1fr'
        })}>
          {subPages ? <div><SubNav pages={subPages} /></div> : null}
          {fixedWidth ? (
            <div className={css({
              maxWidth: `${theme.breakpoints.large}px`,
              minHeight: 'calc(100vh - 70px)',
              width: '100%',
              padding: theme.sizing.scale600,
              [theme.mediaQuery.medium]: {
                padding: theme.sizing.scale900,
              },
            })}>{isMounted ? children : null}</div>
          ) : (
            <div className={css({ width: '100%', minHeight: 'calc(100vh - 70px)', })}>{isMounted ? children : null}</div>
          )}
        </div>
      </>
    );
  };
